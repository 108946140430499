import { PermissionGroup } from "@/react/types";

const leasePermissions: PermissionGroup = {
  name: "Leases",
  key: "leases",
  description: null,
  permissions: {
    view: {
      name: "View Lease",
      key: "lease_permission_view",
      description: null,
    },
    update: {
      name: "Update Lease",
      key: "lease_permission_update",
      description: null,
    },
    create: {
      name: "Create Lease",
      key: "lease_permission_create",
      description: null,
    },
    delete: {
      name: "Delete Lease",
      key: "lease_permission_delete",
      description: null,
    },
  },
};

export default leasePermissions;
