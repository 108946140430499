import { PermissionGroup } from "@/react/types";

const accessCodePermissions: PermissionGroup = {
  name: "Access Codes",
  key: "access_codes",
  description: null,
  permissions: {
    view: {
      name: "View Access Code",
      key: "access_code_permission_view",
      description: null,
    },
    update: {
      name: "Update Access Code",
      key: "access_code_permission_update",
      description: null,
    },
    create: {
      name: "Create Access Code",
      key: "access_code_permission_create",
      description: null,
    },
    delete: {
      name: "Delete Access Code",
      key: "access_code_permission_delete",
      description: null,
    },
    receive_vacant_code: {
      name: "Receive Vacant Access Code",
      key: "access_code_permission_receive_vacant_code",
      description:
        "This permission is in the process of being deprecated. For users with the Vacant Access permission and view backup code permission, you can visit the property access tab.",
    },
    view_backup_code: {
      name: "View Backup Access Code",
      key: "access_code_permission_backup_code_view",
      description: null,
    },
  },
};

export default accessCodePermissions;
