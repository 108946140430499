import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import warning from "tiny-warning";
import { StyleSheet } from "react-native";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import {
  ThemeProvider,
  ActionSheetProvider,
  PlatformProvider,
  ToastProvider,
} from "@smartrent/ui";

import { UserProvider as MgmtUserProvider } from "../context/mgmt-api/user";
import { UserProvider as ResidentUserProvider } from "../context/resident-api/user";
import { UserProvider as SupportUserProvider } from "../context/support-api/user";

import { DialogProvider } from "../context/dialog";
import { ModalProvider } from "../context/modal";
import { $BundleProps } from "../types/BundleProps";
import { createQueryClient } from "../queries/config";

const reactQueryClient = createQueryClient();

function bundlePropsWarning(bundleProps?: any) {
  if (bundleProps === null || typeof bundleProps !== "object") {
    warning(
      false,
      `expected bundleProps to be an object, but instead got ${typeof bundleProps}`
    );
    return;
  }

  const allowedKeys = ["csrf", "uploadcareKey", "flash"];

  const bundlePropsKeys = Object.keys(bundleProps).filter(
    (key) => !allowedKeys.includes(key)
  );

  warning(
    bundlePropsKeys.length === 0,
    `Found the following keys in bundleProps: ${bundlePropsKeys.join(
      ", "
    )}. If they are not used, remove them from the Phoenix template corresponding to this URL.`
  );
}

const createBundle = (
  bundleName: string,
  render: (bundleProps: $BundleProps) => React.ReactElement
) => {
  const isResident =
    window.location.pathname.startsWith("/resident") ||
    window.location.pathname.startsWith("/oauth");
  const isSupport = window.location.pathname.startsWith("/support");
  const isMaintenance = window.location.pathname.startsWith("/mt");
  const isUnauthenticated = bundleName === "unauthenticated";

  document
    .querySelectorAll(`[data-react-bundle="${bundleName}"]`)
    .forEach((element) => {
      const bundleProps = JSON.parse(
        (element as HTMLElement).dataset.bundleProps ?? "{}"
      );

      let UserProvider;
      if (isResident) {
        UserProvider = ResidentUserProvider;
      } else if (isSupport) {
        UserProvider = SupportUserProvider;
      } else if (isMaintenance || isUnauthenticated) {
        UserProvider = React.Fragment; // noop
      } else {
        UserProvider = MgmtUserProvider;
      }

      if (process.env.NODE_ENV === "development") {
        bundlePropsWarning(bundleProps);
      }

      ReactDOM.render(
        <Router>
          <QueryClientProvider client={reactQueryClient}>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            <ThemeProvider initialMode="light">
              <UserProvider>
                <ToastProvider>
                  <GestureHandlerRootView
                    style={[
                      styles.gestureRoot,
                      bundleName === "devtools"
                        ? { position: "absolute" }
                        : null,
                    ]}
                  >
                    <Suspense fallback={null}>
                      <PlatformProvider>
                        <ModalProvider>
                          <DialogProvider>
                            <ActionSheetProvider>
                              {render(bundleProps)}
                            </ActionSheetProvider>
                          </DialogProvider>
                        </ModalProvider>
                      </PlatformProvider>
                    </Suspense>
                  </GestureHandlerRootView>
                </ToastProvider>
              </UserProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </Router>,
        element
      );
    });
};

export default createBundle;

const styles = StyleSheet.create({
  gestureRoot: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
});
