import { instance } from "@/react/hooks/api";
import { createAxiosQuery } from "@/react/hooks/react-query";
import { User } from "@/react/types/users/user";

const API_NAMESPACE = "support-api";

export const useCurrentUserQuery = createAxiosQuery<void, User>(
  "current-user",
  async () => {
    const { data } = await instance({ apiNamespace: API_NAMESPACE }).get<User>(
      "/users/me"
    );
    return data;
  },
  {
    // for the purposes of support-api, we are considered logged in IFF window.websocketAccessToken
    // has a value (it is undefined on public pages)
    enabled: !!window.websocketAccessToken,
    // We can safely cache this data forever and manually invalidate the cache when the user edits their profile.
    staleTime: Infinity,
  }
);
