import { PermissionGroup } from "@/react/types";

const prospectPermissions: PermissionGroup = {
  name: "Prospects",
  key: "prospects",
  description: null,
  permissions: {
    view: {
      name: "View Prospect",
      key: "prospect_permission_view",
      dependsOn: ["tours.view", "tours.create"],
      description: null,
    },
    update: {
      name: "Update Prospect",
      key: "prospect_permission_update",
      dependsOn: ["tours.view"],
      description: null,
    },
    create: {
      name: "Create Prospect",
      key: "prospect_permission_create",
      dependsOn: ["tours.view", "tours.create"],
      description: null,
    },
    delete: {
      name: "Delete Prospect",
      key: "prospect_permission_delete",
      dependsOn: ["tours.view"],
      description: null,
    },
  },
};

export default prospectPermissions;
