import { PermissionGroup } from "@/react/types";

const rolePermissions: PermissionGroup = {
  name: "Roles",
  key: "roles",
  description: null,
  permissions: {
    manage: {
      name: "Manage Roles",
      key: "role_permission_manage",
      description:
        "Users with this permission will be able to manage the roles for this organization. The user will only be able to assign permissions that they currently have.",
    },
  },
};

export default rolePermissions;
