import { PermissionGroup } from "@/react/types";

const unitPermissions: PermissionGroup = {
  name: "Units",
  key: "units",
  description: null,
  permissions: {
    view: {
      name: "View Unit",
      key: "unit_permission_view",
      defaultValue: true,
      disabled: (_roleSettings, _rolePermissions) => true,
      description: null,
    },
    update: {
      name: "Update Unit",
      key: "unit_permission_update",
      description: null,
    },
    create: {
      name: "Create Unit",
      key: "unit_permission_create",
      description: null,
    },
    delete: {
      name: "Delete Unit",
      key: "unit_permission_delete",
      description: null,
    },
  },
};

export default unitPermissions;
