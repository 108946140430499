import { PermissionGroup } from "@/react/types";

const reportPermissions: PermissionGroup = {
  name: "Reports",
  key: "reports",
  description: null,
  show: (_organization, _currentUser, _role, props = {}) => {
    // -- whether or not this group should show up in the permissions list.
    if (props && props?.reportSetsEnabled?.enabled) {
      return true;
    }

    return false;
  },
  permissions: {
    manage_organization_report_sets: {
      name: "Manage Organization Report Sets",
      key: "report_permission_manage_organization_report_sets",
      description:
        "This gives a user permission to create organization report sets. Organizations report sets will be visible by all users with this permission, and will not be deleted when a user is deleted or if this permission is revoked. If a user is given both Manage Personal Report Sets and Manage Organization Reports, Organization Reports functionality will take priority.",
    },
    manage_personal_report_sets: {
      name: "Manage Personal Report Sets",
      key: "report_permission_manage_personal_report_sets",
      description:
        "This gives a user permission to create private report sets. Private report sets will only be visible by the user who created them, and will be deleted when a user is deleted or if this permission is revoked. If a user is given both Manage Personal Report Sets and Manage Organization Reports, Organization Reports functionality will take priority.",
    },
    manage_delivery_method: {
      name: "Manage Delivery Method",
      key: "report_permission_manage_delivery_method",
      disabled: (_roleSettings, rolePermissions) =>
        !rolePermissions.report_permission_manage_organization_report_sets &&
        !rolePermissions.report_permission_manage_personal_report_sets,
      description:
        "To enable this permission, you need to have either the Manage Organization Reports or Manage Personal Reports permission.",
    },
  },
};

export default reportPermissions;
