import React, { createContext, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";

import {
  ResidentUserProfile,
  useCurrentUserQuery,
} from "@/react/queries/resident/current-user";

// Do not export the context directly to prevent consumers from accidentally
// getting the default state since it doesn't match the type definition.
const UserContext = createContext<ResidentUserProfile>(undefined as any);

export const useCurrentUser = () => useContext(UserContext);

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { data, isLoading, error } = useCurrentUserQuery({});

  useEffect(() => {
    if (data?.id) {
      Sentry.setUser({
        id: String(data.id),
      });
    }
  }, [data?.id]);

  if (isLoading || error) {
    return null;
  }

  return (
    <UserContext.Provider value={data as ResidentUserProfile}>
      {children}
    </UserContext.Provider>
  );
};
