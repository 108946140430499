import { PermissionGroup } from "@/react/types";

const communityDefaultPermissions: PermissionGroup = {
  name: "Community Defaults",
  key: "community_defaults",
  description: null,
  permissions: {
    update: {
      name: "Update Community Defaults",
      key: "community_defaults_permission_update",
      description: null,
    },
  },
};

export default communityDefaultPermissions;
