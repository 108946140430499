import React from "react";
import { ActivityIndicator, useTheme } from "@smartrent/ui";

const Loader = ({
  size = 32,
  duration = 1000,
  color,
}: {
  size?: number;
  duration?: number;
  color?: string;
}) => {
  const { colors } = useTheme();

  return (
    <ActivityIndicator
      size={size}
      duration={duration}
      color={color || colors.primary}
    />
  );
};

export default Loader;
