import { PermissionGroup } from "@/react/types";

const integrationPermissions: PermissionGroup = {
  name: "Integrations",
  key: "integrations",
  description: null,
  permissions: {
    view: {
      name: "View Integration",
      key: "integration_permission_view",
      description: null,
    },
    sync: {
      name: "Sync Integration",
      key: "integration_permission_sync",
      description: null,
    },
    update: {
      name: "Update Integration",
      key: "integration_permission_update",
      description: null,
    },
    create: {
      name: "Create Integration",
      key: "integration_permission_create",
      description: null,
    },
    delete: {
      name: "Delete Integration",
      key: "integration_permission_delete",
      description: null,
    },
  },
};

export default integrationPermissions;
