import { PermissionGroup } from "@/react/types";

const userPermissions: PermissionGroup = {
  name: "Users",
  key: "users",
  description: null,
  permissions: {
    view: {
      name: "View User",
      key: "user_permission_view",
      description: null,
    },
    update: {
      name: "Update User",
      key: "user_permission_update",
      description: null,
    },
    create: {
      name: "Create User",
      key: "user_permission_create",
      description: null,
    },
    delete: {
      name: "Delete User",
      key: "user_permission_delete",
      description: null,
    },
  },
};

export default userPermissions;
