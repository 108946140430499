import { PermissionGroup } from "@/react/types";

const hubPermissions: PermissionGroup = {
  name: "Hubs",
  key: "hubs",
  description: null,
  permissions: {
    view: {
      name: "View Hub",
      key: "hub_permission_view",
      dependsOn: ["devices.view"],
      description: null,
    },
    create: {
      name: "Create Hub",
      key: "hub_permission_create",
      description: null,
    },
    delete: {
      name: "Delete Hub",
      key: "hub_permission_delete",
      description: null,
    },
  },
};

export default hubPermissions;
