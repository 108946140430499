import { PermissionGroup } from "@/react/types";

const devicePermissions: PermissionGroup = {
  name: "Devices",
  key: "devices",
  description: null,
  permissions: {
    view: {
      name: "View Device",
      key: "device_permission_view",
      description: null,
    },
    update: {
      name: "Update Device",
      key: "device_permission_update",
      description: null,
    },
    create: {
      name: "Create Device",
      key: "device_permission_create",
      description: null,
    },
    delete: {
      name: "Delete Device",
      key: "device_permission_delete",
      description: null,
    },
    control: {
      name: "Control Device",
      key: "device_permission_control",
      description: null,
    },
  },
};

export default devicePermissions;
