import { instance } from "@/react/hooks/api";
import { createAxiosQuery } from "@/react/hooks/react-query";

export interface ResidentUserProfile {
  id: number;
  email: string;
  first_name: string | null;
  last_name: string | null;
  mobile_phone: string | null;
  tos_accepted_at: string | null;
  user_needs_to_accept_tos: boolean;
}

export const useCurrentUserQuery = createAxiosQuery(
  "current-user",
  async () => {
    const { data } = await instance({
      apiNamespace: "api",
    }).get<ResidentUserProfile>(`/users/me`);

    return data;
  }
);
