import { PermissionGroup } from "@/react/types";

const parkingPermissions: PermissionGroup = {
  name: "Parking",
  key: "parking",
  description: null,
  permissions: {
    view: {
      name: "View Parking",
      key: "parking_permission_view",
      dependsOn: ["residents.view"],
      description: null,
    },
    manage_settings: {
      name: "Manage Parking Settings",
      key: "parking_permission_manage_settings",
      dependsOn: ["residents.view"],
      description: null,
    },
    manage_spaces: {
      name: "Manage Parking Spaces",
      key: "parking_permission_manage_spaces",
      dependsOn: ["residents.view"],
      description: null,
    },
    manage_vehicles: {
      name: "Manage Parking Vehicles",
      key: "parking_permission_manage_vehicles",
      dependsOn: ["residents.view"],
      description: null,
    },
    manage_rates: {
      name: "Manage Parking Rates",
      key: "parking_permission_manage_rates",
      dependsOn: ["residents.view"],
      description: null,
    },
    assign_spaces: {
      name: "Assign Parking Spaces",
      key: "parking_permission_assign_spaces",
      dependsOn: ["residents.view"],
      description: null,
    },
    assign_decals: {
      name: "Assign Parking Decals",
      key: "parking_permission_assign_decals",
      dependsOn: ["residents.view"],
      description: null,
    },
    validate_parking: {
      name: "Validate Parking",
      key: "parking_permission_validate_parking",
      dependsOn: ["residents.view"],
      description: null,
    },
  },
};

export default parkingPermissions;
