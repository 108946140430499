import { PermissionGroup } from "@/react/types";

const tourPermissions: PermissionGroup = {
  name: "Tours",
  key: "tours",
  description: null,
  permissions: {
    view: {
      name: "View Tour",
      key: "tour_permission_view",
      dependsOn: ["prospects.view"],
      description: null,
    },
    create: {
      name: "Create Tour",
      key: "tour_permission_create",
      dependsOn: ["prospects.create"],
      description: null,
    },
    delete: {
      name: "Delete Tour",
      key: "tour_permission_delete",
      description: null,
    },
    update: {
      name: "Update Tour",
      key: "tour_permission_update",
      description: null,
    },
    manage_settings: {
      name: "Manage Tour Settings",
      key: "tour_permission_manage_settings",
      description: null,
    },
    manage_custom_messages: {
      name: "Manage Custom Messages",
      key: "tour_permission_manage_custom_messages",
      description: null,
    },
    allow_skipping_id_verification: {
      name: "Allow Skipping ID Verification",
      key: "tour_permission_allow_skipping_id_verification",
      description: null,
    },
    manage_unit_availability: {
      name: "Manage Unit Availability",
      key: "tour_permission_manage_unit_availability",
      dependsOn: ["units.update"],
      description: null,
    },
    view_dashboard: {
      name: "View Dashboard",
      key: "tour_permission_view_dashboard",
      description: null,
    },
    view_hub: {
      name: "View Hub",
      key: "tour_permission_view_hub",
      description: null,
    },
  },
};

export default tourPermissions;
