import { PermissionGroup } from "@/react/types";

const supportPermissions: PermissionGroup = {
  name: "Support",
  key: "support",
  description: null,
  show: (_organization, _currentUser, _role, props = {}) => {
    // -- whether or not this group should show up in the permissions list.
    if (props && props?.supportDashboardFeatureFlag?.enabled) {
      return true;
    }

    return false;
  },
  permissions: {
    view_dashboard: {
      name: "View Dashboard",
      key: "support_permission_view_dashboard",
      description: null,
    },
  },
};

export default supportPermissions;
