import { PermissionGroup } from "@/react/types";

const workOrderPermissions: PermissionGroup = {
  name: "Maintenance",
  key: "work_orders",
  description: null,
  permissions: {
    manage_maintenance_settings: {
      name: "Manage Maintenance Settings",
      key: "work_order_permission_manage_maintenance_settings",
      description:
        "Users with this permission will be able to view and manage maintenance settings for properties they are assigned to.",
    },
    view: {
      name: "View Work Order",
      key: "work_order_permission_view",
      description: null,
    },
    update: {
      name: "Update Work Order",
      key: "work_order_permission_update",
      description: null,
    },
    create: {
      name: "Create Work Order",
      key: "work_order_permission_create",
      description: null,
    },
    assign: {
      name: "Can Be Assigned Work Order",
      key: "work_order_permission_can_be_assigned",
      description: null,
    },
  },
};

export default workOrderPermissions;
