import { PermissionGroup } from "@/react/types";

const vendorPermissions: PermissionGroup = {
  name: "Vendors",
  key: "vendors",
  description: null,
  permissions: {
    view: {
      name: "View Vendor",
      key: "vendor_permission_view",
      description: null,
    },
    manage: {
      name: "Manage Vendor",
      key: "vendor_permission_manage",
      description: null,
    },
  },
};

export default vendorPermissions;
