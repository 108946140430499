import { PermissionGroup } from "@/react/types";

const notificationPermissions: PermissionGroup = {
  name: "Notifications",
  key: "notifications",
  description: null,
  permissions: {
    view: {
      name: "View Notification",
      key: "notification_permission_view",
      description: null,
    },
    update: {
      name: "Update Notification",
      key: "notification_permission_update",
      description: null,
    },
  },
};

export default notificationPermissions;
