import { PermissionGroup } from "@/react/types";

const scopeOfWorkPermissions: PermissionGroup = {
  name: "Projects",
  key: "scope_of_work",
  description: null,
  permissions: {
    view: {
      name: "View Scope Of Work",
      key: "scope_of_work_permission_view",
      description: null,
    },
    manage: {
      name: "Manage Scope Of Work",
      key: "scope_of_work_permission_manage",
      description: null,
    },
  },
};

export default scopeOfWorkPermissions;
