import { PermissionGroup } from "@/react/types";

const residentPermissions: PermissionGroup = {
  name: "Residents",
  key: "residents",
  description: null,
  permissions: {
    view: {
      name: "View Resident",
      key: "resident_permission_view",
      description: null,
    },
    update: {
      name: "Update Resident",
      key: "resident_permission_update",
      description: null,
    },
    create: {
      name: "Create Resident",
      key: "resident_permission_create",
      description: null,
    },
    delete: {
      name: "Delete Resident",
      key: "resident_permission_delete",
      description: null,
    },
    move_in: {
      name: "Move-In Resident",
      key: "resident_permission_move_in",
      description: null,
    },
    move_out: {
      name: "Move-Out Resident",
      key: "resident_permission_move_out",
      description: null,
    },
    send_backup_code: {
      name: "Send Backup Code to Resident",
      key: "resident_permission_send_backup_code",
      description: null,
    },
    resend_resident_code: {
      name: "Resend Resident Code",
      key: "resident_permission_resend_resident_code",
      description: null,
    },
    reset_resident_code: {
      name: "Reset Resident Code",
      key: "resident_permission_reset_resident_code",
      description: null,
    },
    enable_wifi_enrollment: {
      name: "Enable Resident WiFi Enrollment",
      key: "resident_permission_enable_wifi_enrollment",
      description: null,
    },
    manage_settings: {
      name: "Manage Resident Settings",
      key: "resident_permission_manage_settings",
      description: null,
    },
    manage_app_white_label_settings: {
      name: "Manage Resident App White Label Settings",
      key: "resident_permission_manage_app_white_label_settings",
      description: null,
      dependsOn: ["residents.manage_settings"],
    },
    manage_amenities: {
      name: "Manage Amenities",
      key: "resident_permission_manage_amenities",
      description: null,
    },
  },
};

export default residentPermissions;
