import { PermissionGroup } from "@/react/types";

const organizationReportPermissions: PermissionGroup = {
  name: "Organization Report",
  key: "organization_reports",
  description: null,
  show: (_organization, _currentUser, _role, props = {}) => {
    // -- whether or not this group should show up in the permissions list.
    if (props.reportSetsEnabled?.enabled) {
      return false;
    }

    return true;
  },
  permissions: {
    view: {
      name: "View Organization Report",
      key: "organization_report_permission_view",
      description: null,
    },
    manage: {
      name: "Manage Organization Report",
      key: "organization_report_permission_manage",
      description: null,
    },
  },
};

export default organizationReportPermissions;
