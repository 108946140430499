import { PermissionGroup, RoleSettings } from "@/react/types";

const organizationPermissions: PermissionGroup = {
  name: "Organizations",
  key: "organizations",
  description: null,
  permissions: {
    manage_profile_settings: {
      name: "Manage Organization Profile Settings",
      key: "organization_permission_manage_profile_settings",
      description: null,
    },
    manage_imports: {
      name: "Manage Imports",
      key: "organization_permission_manage_imports",
      description: null,
      requireSettings: [RoleSettings.assign_all_properties],
    },
    manage_tour_settings: {
      name: "Manage Organization Tour Settings",
      key: "organization_permission_manage_tour_settings",
      description: null,
    },
    manage_templates: {
      name: "Manage Organization Templates",
      key: "organization_permission_manage_templates",
      description: null,
    },
    manage_ecommerce_settings: {
      name: "Manage eCommerce Settings",
      key: "organization_permission_manage_ecommerce",
      description: null,
    },
    manage_regions: {
      name: "Manage Regions",
      key: "organization_permission_manage_regions",
      description: null,
    },
    view_credentials: {
      name: "View Organization Credentials",
      key: "organization_permission_view_credentials",
      description: null,
      dependsOn: ["integrations.view"],
    },
    manage_credentials: {
      name: "Manage Organization Credentials",
      key: "organization_permission_manage_credentials",
      description: null,
      dependsOn: ["organizations.view_credentials", "integrations.view"],
    },
    manage_webhooks: {
      name: "Manage Organization Webhooks",
      key: "organization_permission_manage_webhooks",
      description: null,
      requireSettings: [RoleSettings.assign_all_properties],
    },
  },
};

export default organizationPermissions;
